<template>
  <a-row>
    <a-col
      :md="{ span: 12, offset: 6 }"
      :sm="{ span: 16, offset: 4 }"
      :xs="{ span: 20, offset: 2 }"
    >
      <div class="logo-wrapper">
        <div class="logo" />
      </div>
    </a-col>
    <a-col
      :md="{ span: 12, offset: 6 }"
      :sm="{ span: 16, offset: 4 }"
      :xs="{ span: 20, offset: 2 }"
      style="padding-bottom: 10px"
    >
      <h1>Register Hotel Account</h1>
      <a-alert
        message="Informational Notes : Before applying, 'Hotel Logo (1:1 size)' and 'Hotel Business License' must be prepared in JPEG or PNG extension, size no larger than 5MB."
        type="info"
        showIcon
      >
        <template #icon><smile-outlined /></template>
      </a-alert>
    </a-col>
  </a-row>
  <a-row style="padding-bottom: 30px">
    <a-col
      :md="{ span: 12, offset: 6 }"
      :sm="{ span: 16, offset: 4 }"
      :xs="{ span: 20, offset: 2 }"
    >
      <a-form
        ref="formRef"
        :model="formState"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-item label="First name" name="firstname">
          <a-input
            v-model:value="formState.firstname"
            placeholder="Your first name"
            size="large"
          />
        </a-form-item>

        <a-form-item label="Last name" name="lastname">
          <a-input
            v-model:value="formState.lastname"
            placeholder="Your Last name"
            size="large"
          />
        </a-form-item>

        <a-form-item label="Position" name="position">
          <a-input
            v-model:value="formState.position"
            placeholder="Your position in hotel"
            size="large"
          />
        </a-form-item>

        <a-form-item label="Email" name="email">
          <a-input
            v-model:value="formState.email"
            placeholder="Your email"
            size="large"
            type="email"
          />
        </a-form-item>

        <a-form-item label="Phone number (Username)" name="phoneNumber">
          <a-input
            v-model:value="formState.phoneNumber"
            placeholder="Your phone number"
            size="large"
          />
        </a-form-item>

        <a-form-item label="Password" name="password">
          <a-input-password
            v-model:value="formState.password"
            placeholder="Your password"
            size="large"
          />
        </a-form-item>

        <a-form-item label="Confirm password" name="confirmPassword">
          <a-input-password
            v-model:value="formState.confirmPassword"
            placeholder="Confirm your password"
            size="large"
          />
        </a-form-item>

        <a-row style="justify-content: center">
          <a-button
            type="primary"
            size="large"
            @click="onSubmit"
            :disabled="disableSubmitButton"
            class="main-button"
          >
            <template #icon>
              <RightSquareOutlined />
            </template>
            Submit
          </a-button>
        </a-row>
      </a-form>
    </a-col>
  </a-row>
</template>

<script>
import { defineComponent, reactive, toRaw, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { message } from "ant-design-vue";
import { useStore } from "vuex";
import { isEmpty } from "lodash";
import { SmileOutlined, RightSquareOutlined } from "@ant-design/icons-vue";

export default defineComponent({
  components: {
    SmileOutlined,
    RightSquareOutlined,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const formRef = ref();
    const disableSubmitButton = ref(false);

    const formState = reactive({
      firstname: "",
      lastname: "",
      email: "",
      position: "",
      phoneNumber: "",
      password: "",
      confirmPassword: "",

      // to-do: เสร็จแล้วลบด้วย
      // firstname: "Kai",
      // lastname: "noi",
      // email: "kainoi@test.com",
      // position: "GM",
      // phoneNumber: "0812345671",
      // password: "12345678",
      // confirmPassword: "12345678",
    });

    const rules = {
      firstname: [{ required: true, message: "Your first name is required" }],
      lastname: [{ required: true, message: "Your last name is required" }],
      email: [
        { required: true, message: "Your email is required" },
        {
          validator(_, value) {
            const re =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            if (value.length != 0 && re.test(`${value}`.toLowerCase())) {
              return Promise.resolve();
            }
            return Promise.reject("Invalid Email");
          },
        },
      ],
      position: [{ required: true, message: "Your position is required" }],
      phoneNumber: [
        { required: true, message: "Your phone number is required" },
        {
          max: 10,
          message: "Phone number cannot be longer than 10 characters",
        },
        {
          validator(word, value) {
            if (!isEmpty(value)) {
              const re = /^[0-9]*$/;

              if (
                re.test(`${value}`.toLowerCase()) &&
                `${value}`.length <= 10 &&
                `${value}`.length > 0
              ) {
                return Promise.resolve();
              }
              return Promise.reject(
                "Invalid phone number and phone number must be 10 characters"
              );
            } else {
              return Promise.resolve();
            }
          },
        },
      ],
      password: [
        { required: true, message: "Your password is required" },
        {
          validator(_, value) {
            const re = /^[*.!@$%^&;<>,.?/~_+-=|0-9a-zA-Z]{8,15}$/;

            if (re.test(`${value}`)) {
              return Promise.resolve();
            }
            return Promise.reject("Invalid Password");
          },
        },
      ],
      confirmPassword: [
        { required: true, message: "Confirm password is required" },
        {
          validator(_, value) {
            if (value === formState.password) {
              return Promise.resolve();
            }
            return Promise.reject(
              "Password confirmation doesn't match Password"
            );
          },
        },
      ],
    };

    const onSubmit = () => {
      formRef.value
        .validate()
        .then(async () => {
          try {
            disableSubmitButton.value = true;
            await store.dispatch(`auth/register`, formState);
            message.success("Register success.");
          } catch (e) {
            console.log(e);
            disableSubmitButton.value = false;
            message.error(
              "This is an error message, This may be due to duplicate phone numbers, please try again."
            );
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    };

    watch(
      () => store.getters["auth/currentUser"],
      (val) => {
        if (val && val.token && val.token.length > 0) {
          setTimeout(() => {
            router.push({ name: "fillup" });
          }, 200);
        }
      }
    );

    return {
      labelCol: {
        span: 24,
      },
      wrapperCol: {
        span: 24,
      },
      formState,
      formRef,
      onSubmit,
      rules,
      disableSubmitButton,
    };
  },
});
</script>

<style scoped>
.logo {
  width: 75%;
  padding-top: 35%;
  background: url("../assets/evenmore-logo.jpg") no-repeat;
  background-size: 100% auto;
}
.logo-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.main-button {
  background: #57b947;
  border-color: #57b947;
  color: #ffffff;
  font-weight: bold;
}
.main-button[disabled] {
  background: #f5f5f5;
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.25);
  font-weight: bold;
}
</style>
